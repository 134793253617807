import { createStore } from "vuex";

import { sendAPIRequestV2 } from "./requests";

const store = createStore({
  state() {
    return {
      cropColor: null,
      cropKind: null,
      cropAnswers: [],
      filters: null,
      biome: null,
      pvpoNumber: null,
      searchResults: [],
      showDataModal: false,
      dataModalSpecies: null,
      varietyName: null,
    };
  },
  mutations: {
      applyFilters(state) {
      state.searchResults = [];
      state.filters = setFilters(state);
      updateSearchResults(state.filters);
    },
    clearFilters(state) {
      state.cropColor = null;
      state.cropKind = null;
      state.cropAnswers = [];
      state.searchResults = [];
      state.biome = null;
      state.pvpoNumber = null;
      state.varietyName = null;

      state.filters = setFilters(state);
    },
    setCropColor(state, cropColor) {
      state.cropColor = cropColor;
    },
    setCropKind(state, cropKind) {
      state.cropKind = cropKind;
    },
    setCropAnswers(state, cropAnswers) {
      state.cropAnswers = cropAnswers;
      //console.log("ALL ANSWERS: ", state.cropAnswers);
    },
    setBiome(state, biome) {
      state.biome = biome;
    },
    setPvpoNumber(state, pvpoNumber) {
      state.pvpoNumber = pvpoNumber;
    },
    setSearchResults(state, results) {
      state.searchResults = results;
    },
    toggleShowDataModal(state) {
      state.showDataModal = !state.showDataModal;
    },
    setDataModalSpecies(state, data) {
      state.dataModalSpecies = data;
    },
    setVarietyName(state, varietyName) {
      state.varietyName = varietyName;
    },
  },
});

export default store;

function setFilters(state) {
  return {
    cropColor: state.cropColor,
    cropKind: state.cropKind,
    cropAnswers : state.cropAnswers,
    biome: state.biome,
    pvpoNumber: state.pvpoNumber,
    varietyName: state.varietyName,
  };
}

async function updateSearchResults(filters){
    const params = generateParams(filters);
    //console.log('params: ', params);
    const response = await sendAPIRequestV2("pv", params);
  store.commit("setSearchResults", response);
}

function generateParams(filters) {

    const params = {};
    for (const [key, value] of Object.entries(filters)) {
        if (value && value != filters.cropAnswers) {
            params[key] = value;
        }
        if (value == filters.cropAnswers && value.length != 0) {
            const answers = Array.from(filters.cropAnswers);
            let combined = "";

            const jsonString = JSON.stringify(answers, (key, value) => {
                //console.log("key: ", key)
                if (typeof value !== 'object') {
                    combined += value + ';';
                }
                if (key !== '' && Array.isArray(value)) {
                    return value.join(';');
                }
                return value;
            });
            //console.log("Combined: " + combined);

            jsonString.split(';');
            params[key] = combined;
        }
    }






    return params;
}
