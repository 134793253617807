<template>
  <div class="filter-menu">
    <IDFilters />
    &nbsp;
    <AttributeFilters />
    &nbsp;
    <LocationFilters />
    <span class="buttons">
      <Button label="Search" @click="applyFilters" />
      &nbsp;
      <Button
        class="p-button-warning"
        label="Clear Filters"
        @click="clearFilters"
      />
    </span>
  </div>
</template>

<script>
import Button from "primevue/button";

import IDFilters from "./IDFilters.vue";
import LocationFilters from "./LocationFilters.vue";
import AttributeFilters from "./AttributeFilters.vue";
import store from "@/utils/store";

export default {
  name: "FilterMenu",
  components: {
    Button,
    IDFilters,
    LocationFilters,
    AttributeFilters,
  },
  setup() {
    function applyFilters() {
      store.commit("applyFilters");
    }

    function clearFilters() {
      store.commit("clearFilters");
    }

    return {
      applyFilters,
      clearFilters,
    };
  },
};
</script>

<style scoped>
.buttons {
  float: right;
}

.filter-menu {
  background-color: var(--blue-800);
  color: white;
  padding: 12px 16px;
}
</style>

<style>
.clear-filter-button {
  margin-top: 10px;
}
</style>
