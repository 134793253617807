<template>
  <div id="map-view"></div>
</template>

<script>
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Search from "@arcgis/core/widgets/Search";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { onMounted, watch } from "vue";

import store from "@/utils/store";

export default {
  name: "ArcGISMap",
  setup() {
    const ecoregionsLayer = new FeatureLayer({
      portalItem: {
        id: "37ea320eebb647c6838c23f72abae5ef",
      },
      popupTemplate: {
        title: "{BIOME_NAME}",
        content: "Ecoregion: {ECO_NAME}",
      },
      opacity: 0.35,
    });
    let view;
    const highlightedGraphics = [];

    onMounted(() => {
      const map = setUpMap();
      setUpMapView(map);
    });

    function setUpMap() {
      const map = new Map({
        basemap: "topo-vector",
      });

      map.add(ecoregionsLayer);

      return map;
    }

    function setUpMapView(map) {
      view = new MapView({
        container: "map-view",
        map: map,
        zoom: 4,
        center: [-96, 38],
      });

      view.ui.add(new Search({ view: view }), { position: "top-right" });

      view.on("click", (event) => onMapViewClick(event.screenPoint));
    }

    function onMapViewClick(screenPoint) {
      view
        .hitTest(screenPoint)
        .then((response) => {
          try {
            updatupdateBiome(response);
          } catch (error) {
            store.commit("setBiome", null);
          }
        })
        .then(() => {
          view.whenLayerView(ecoregionsLayer).then((layerView) => {
            higlightBiome(layerView);
          });
        });
    }

    function updatupdateBiome(response) {
      let graphic = response.results.filter((result) => {
        return result.graphic.layer === ecoregionsLayer;
      })[0].graphic;

      store.commit("setBiome", graphic.attributes.BIOME_NAME.replace(/&/g, 'and'));
    }

    function higlightBiome(layerView) {
      layerView.queryFeatures().then((results) => {
        removeExistingHighlights();

        results.features
          .filter((graphic) => {
            return graphic.attributes.BIOME_NAME === store.state.biome;
          })
          .forEach((graphic) => {
            highlightedGraphics.push(layerView.highlight(graphic));
          });
      });
    }

    function removeExistingHighlights() {
      highlightedGraphics.forEach((highlight) => {
        highlight.remove();
      });

      highlightedGraphics.splice(0, highlightedGraphics.length);
    }

    watch(
      () => store.state.biome,
      () => {
        view.whenLayerView(ecoregionsLayer).then((layerView) => {
          higlightBiome(layerView);
        });
      }
    );
  },
};
</script>

<style>
#map-view {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
</style>
