<template>
  <FilterOptions label="Filter by Attribute">
    Crop Kind
    <Dropdown
      class="w-full"
      :options="cropKinds"
      placeholder="Select Crop Kind"
      :scroll-height="DROPDOWN_SCROLL_HEIGHT"
      v-model="cropKindRef"
    />
    <div v-for="question in cropQuestionsRef" :key="question.Question_Name">
      {{ question.Question_Name }}
      <Dropdown
        class="w-full"
        :options="question.Answers"
        placeholder="Select"
        :scroll-height="DROPDOWN_SCROLL_HEIGHT"
        v-model="selectedAnswers[question.Question_Name]"
        @change="handleDropdown(question)"
      />
    </div>
    <div class="clear-filter-button">
      <Button
        class="p-button-warning w-full"
        label="Clear Attribute Filters"
        @click="clearFilters"
      />
    </div>
  </FilterOptions>
</template>

<script>
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import { onMounted, ref, watch } from "vue";

import FilterOptions from "./FilterOptions.vue";
import store from "@/utils/store";
import { sendAPIRequest } from "@/utils/requests";
import { sendAPIRequestV2 } from "@/utils/requests";
import { DROPDOWN_SCROLL_HEIGHT } from "@/utils/constants";

export default {
  name: "LocationFilter",
  components: {
    Button,
    Dropdown,
    FilterOptions,
  },
  setup() {
    const cropKinds = ref();
    const cropKindRef = ref();
    const cropQuestionsRef = ref();
    const selectedAnswers = ref({});

    onMounted(async () => {
      cropKinds.value = await fetchCropKinds();
    });

    watch(cropKindRef, async () => {
      store.commit("setCropKind", cropKindRef.value);
        selectedAnswers.value = {};
        answerMap.clear();

      if (cropKindRef.value) {
        cropQuestionsRef.value = await fetchQuestions(cropKindRef.value);
      } else {
        cropQuestionsRef.value = [];
      }
    });

    watch(
      () => store.state.cropKind,
      () => {
        cropKindRef.value = store.state.cropKind;
      }
    );

    function clearFilters() {
      cropKindRef.value = null;
      cropQuestionsRef.value = null;
      selectedAnswers.value = {};
    }

    let answerMap = new Map();
    let tempCropKind;

      function handleDropdown(question) {

          answerMap.clear();

          if (tempCropKind != cropKindRef.value) {
              answerMap.clear();
          }
          tempCropKind = cropKindRef.value;

          
          answerMap.set(question.Question_Name, selectedAnswers.value);
          //console.log("AnswerMap: " + answerMap.values() + " and keys: " + answerMap.keys())
          store.commit("setCropAnswers", (answerMap.values()));
      }


    return {
      DROPDOWN_SCROLL_HEIGHT,
      cropKinds,
      cropKindRef,
      cropQuestionsRef,
      clearFilters,
      handleDropdown,
      selectedAnswers,
    };
  },
};

async function fetchCropKinds() {
  const response = await sendAPIRequest("cropkinds");
  return response;
}

async function fetchQuestions(cropKind) {
    const params = {};
    params["cropKind"] = cropKind;
    //console.log("cropKind: ", cropKind);
    const response = await sendAPIRequestV2("questions", params);
  return response;
}
</script>
