<template>
  <div class="flex flex-column h-full">
    <div class="flex-none">
      <FilterMenu />
    </div>
    <div class="flex-auto">
      <div class="flex flex-row h-full">
        <div class="flex-data-display">
          <DataDisplay />
        </div>
        <div class="flex-map align-self-stretch">
          <ArcGISMap />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArcGISMap from "./Map/ArcGISMap.vue";
import FilterMenu from "./Filter/FilterMenu.vue";
import DataDisplay from "./Data/DataDisplay.vue";

export default {
  name: "PVContent",
  components: {
    ArcGISMap,
    FilterMenu,
    DataDisplay,
  },
};
</script>

<style scoped>
.banner {
  flex: 0 1 auto;
}

.body {
  display: flex;
  height: 100%;
}

.flex-data-display {
  flex: 0 0 33%;
  overflow: auto;
  min-height: 100%;
  max-height: 0px;
}

.flex-row {
  flex-direction: row !important;
}

.flex-map {
  flex: 0 0 67%;
}
</style>

<style>
.clickable-text {
  color: var(--blue-500);
  text-decoration: underline;
  cursor: pointer;
}
</style>
