<template>
  <div class="results">
    <span v-if="!loading">{{ results.length }} Results:</span>
    <i v-if="!loading" class="dropdown pi pi-ellipsis-v" @click="toggleOptions" />
    <span v-if="loading">Loading...</span>
    <i v-if="loading" class="load pi pi-spin pi-spinner" />
  </div>
  <OverlayPanel ref="showOptions">
    <Button
      class="p-button-plain p-button-sm p-button-text"
      icon="pi pi-download"
      label="Export to CSV"
      @click="exportAllResultsCsv"
    />
  </OverlayPanel>
  <div v-if="hasResults">
    <div v-for="result in results" :key="result.PVPO_Number">
      <DataItem :result="result" />
    </div>
  </div>
  <div class="no-results" v-if="!hasResults && !loading">No results for currently applied filters.</div>
  <DataModal />
</template>

<script>
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";
import { ref, watch } from "vue";

import DataItem from "./DataItem.vue";
import DataModal from "./DataModal.vue";
import { exportAllResultsCsv } from "./export";
import store from "@/utils/store";

export default {
  name: "DataDisplay",
  components: {
    Button,
    DataItem,
    DataModal,
    OverlayPanel,
  },
  setup() {
    const results = ref(store.state.searchResults);
    const hasResults = ref(false);
    const loading = ref(false);
    const showOptions = ref();

    function toggleOptions(event) {
      showOptions.value.toggle(event);
    }

     watch(
      () => store.state.filters,
      () => {
        loading.value = true;
      }
    );

    watch(
      () => store.state.searchResults,
      () => {
        results.value = store.state.searchResults;
        loading.value = false;
        hasResults.value = store.state.searchResults.length !== 0;
      }
    );

    return {
      hasResults,
      results,
      showOptions,
      exportAllResultsCsv,
      loading,
      toggleOptions,
    };
  },
};
</script>

<style scoped>
.dropdown {
  float: right;
  cursor: pointer;
}

.load {
  float: right;
  color: #106ebe;
}

.no-results {
  padding: 12px 16px;
}
.results {
  font-weight: bold;
  padding: 12px 16px;
  background-color: var(--gray-50);
}
</style>
