<template>
    <div id="banner">
        <Banner :title="title" :agency="agency" />
    </div>
    <div id="content">
        <PVContent />
    </div>
    <div class="card flex justify-content center">
        <Dialog v-model:visible="visible" modal header="Welcome to the PVPO Online Dashboard (POD)!" :style="{ width: '35vw' }">
            <div class="float-right">
                <img :src="image" style="width: 300px" class="float-right" />
            </div>
            <p>
                We are currently working hard to update and verify the display of our records in this new application. We ask for your patience as some records may contain blank fields at this time. Full records are available upon request. If you are a variety owner and see any errors with your variety records, please contact the PVPO with any corrections at <a href="mailto:PVPOMail@usda.gov">PVPOMail@usda.gov</a>.
            </p>
        </Dialog>
    </div>
    <footer>
        <Button class="footer-button" label="About PVPO" icon="pi pi-book" @click="visible1 = true" />
        <Dialog v-model:visible="visible1" modal header="About PVPO" :style="{ width: '60vw' }">
            <p>
                The Plant Variety Protection Office (PVPO) provides intellectual property protection to breeders of new varieties of sexually reproduced, tuber propagated, and asexually reproduced plant varieties.  Implementing the <a href="https://www.ams.usda.gov/rules-regulations/pvpa">Plant Variety Protection Act (PVPA)</a>, we examine new applications and grant certificates that protect varieties for 20 years (25 years for vines and trees).  Our certificates are recognized worldwide and allow faster filing for PVP in other countries.  Certificate owners have rights to exclude others from marketing and selling their varieties, manage the use of their varieties by other breeders, and enjoy legal protection of their work. <br /> <br />

                In the U.S. there are 3 types of intellectual property protection that breeders can obtain for new plant varieties:<br />
                <ul>
                    <li> Plant Variety Protection - seeds, tubers, and asexually reproduced plants (issued by PVPO)</li>
                    <li>Plant Patents - asexually reproduced plants (issued by the Patent and Trademark Office (PTO)</li>
                    <li>Utility Patents - for genes, traits, methods, plant parts, or varieties (issued by the PTO)</li>
                </ul>

                Our services create an incentive for the development of new and improved varieties. New varieties, potentially better suited for the environment and pest/disease control, can promote agriculture production and food security.

                To apply for a new PVP, review the <a href="https://www.ams.usda.gov/services/plant-variety-protection/pvpo-requirements">application requirements</a>.<br /><br />
            </p>
            <div class="picture">
                <img :src="image1" style="width: 300px" />
            </div>
        </Dialog>
        <Button class="footer-button" label="Our Data" icon="pi pi-user" @click="visible2 = true" />
        <Dialog v-model:visible="visible2" modal header="Our Data" :style="{ width: '60vw' }">
            <p>
                The data presented in this dashboard is gathered during the application process for plant variety certificates. In accordance with the <a href="https://www.ams.usda.gov/rules-regulations/pvpa">Plant Variety Protection Act</a> this data is shared to encourage innovation and promote the progress of plant breeding.<br /> <br />

                The information listed for each variety is based on its official application except the 'Area of Adaptation' field which was extrapolated by the examiners. Therefore the 'Area of Adaptation' is for educational use only, and neither the variety owners nor PVPO can guarantee a variety will work in a specific location.<br /> <br />

                For help locating varieties in your area, please contact your local Extension Office for recommendations.<br />
            </p>
            <div class="picture">
                <img :src="image2" style="width: 300px" />
            </div>
        </Dialog>
        <Button class="footer-button" label="Germplasm" icon="pi pi-plus-circle" @click="visible3 = true" />
        <Dialog v-model:visible="visible3" modal header="Germplasm" :style="{ width: '50vw' }">
            <p>
                A germplasm submission is required for all sexually reproduced varieties and potato varieties that receive a Certificate of Protection. Germplasm storage and maintenance is managed by the USDA National Laboratory for Genetic Resource Preservation in Fort Collins, Colorado.<br /><br />

                Upon the expiration of the Plant Variety Certificate, germplasm that is not protected by other intellectual property rights is released in small quantities for those who wish to grow, research, or breed with this material.<br /><br />

                To find out if a variety is available, please find the Plant Inventory (PI) number on the variety information page and search that number in the <a href="https://npgsweb.ars-grin.gov/gringlobal/search">USDA Germplasm Resource Information Network</a> system. From there you will be able to order seed if it is available.
            </p>
            <div class="picture">
                <img :src="image3" style="width: 300px" />
            </div>
        </Dialog>
    </footer>
</template>

<script>
import PVContent from "./components/PVContent.vue";
import { Banner } from "@ars-pdi/agcros-site-utils";
import { ref } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import image1 from "@/assets/img/AboutPVPO.jpg";
import image2 from "@/assets/img/OurData.jpg";
import image3 from "@/assets/img/Germplasm.jpg";
import image from "@/assets/img/POD.jpg";
import PDI from "@/assets/img/PDI_Logo.png";

export default {
  name: "App",
        data: function () {
            return {
                image: image,
                image1: image1,
                image2: image2,
                image3: image3,
                PDI: PDI
            }
        },
        setup() {
            const visible = ref(true);
            const visible1 = ref(false);
            const visible2 = ref(false);
            const visible3 = ref(false);

            return {
                visible,
                visible1,
                visible2,
                visible3,
            }
        },
  created() {
    this.title = "PVPO Online Dashboard - Plant Variety Protection Search";
    this.agency = "Agricultural Marketing Service";
    this.links = [
      {
        url: "https://www.ams.usda.gov",
        title: "AMS Home",
        desktopview: false,
      },
      {
        url: "https://www.ams.usda.gov/services/plant-variety-protection",
        title: "About PVPO",
        desktopview: false,
      },
      {
        url: "https://npgsweb.ars-grin.gov/gringlobal/search",
        title: "GRIN-Global",
        desktopview: false,
      },
    ];
  },
  components: {
    Banner,
    PVContent,
    Dialog,
    Button,
  },
};
</script>

<style>

    footer {
        text-align: center;
        background-color: var(--gray-50);
    }
    .footer-button {
        margin: 5px 90px;
        text-decoration: underline;
        background: blue;
        background-color: var(--gray-50);
        border: none;
        cursor: pointer;
        color: black;
        font-size: 15px;
    }
    .footer-button:hover {
        text-decoration: none;
    }
    .picture {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .float-right {
        float: right;
        margin: 10px 0 10px 10px;
        height: 200px
    }

html,
body {
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

#banner,
#footer {
  flex: 0 1 auto;
}

#content {
  flex: 1 1 auto;
}
</style>
