import PrimeVue from "primevue/config";
import { createApp } from "vue";

import App from "./App.vue";

import "@arcgis/core/assets/esri/themes/light/main.css";
import "@fontsource/source-sans-pro"
import "primevue/resources/themes/fluent-light/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

const app = createApp(App);

app.use(PrimeVue);
app.mount("#app");
